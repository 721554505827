.loaderWrapper {
    position: absolute;
    width: 100vw;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.loaderContainer {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
}

.loaderInner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.loaderInner.loaderInnerOne {
    left: 0;
    top: 0;
    animation: loaderRotateOne 1s linear infinite;
    border-bottom: 3px solid #000;
}

.loaderInner.loaderInnerTwo {
    right: 0;
    top: 0;
    animation: loaderRotateTwo 1s linear infinite;
    border-right: 3px solid #000;
}

.loaderInner.loaderInnerThree {
    right: 0;
    bottom: 0;
    animation: loaderRotateThree 1s linear infinite;
    border-top: 3px solid #000;
}

@keyframes loaderRotateOne {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes loaderRotateTwo {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes loaderRotateThree {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}