

.globalWrapper {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
}

.mainWrapper {
    padding: 20px 0;
    justify-content: center;
}

.mainContainer {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
    overflow: hidden;
}

/* CONTENT */

.userBar {
    font-size: 22px;
    padding: 20px;
    background: #A21312;
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    user-select: none;
    cursor: default;
}

.time {
    font-size: 14px;
}

.buttonsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 40px 40px 20px 0;
    overflow-y: auto;
}

.niceButton {
    visibility: visible;
    width: 320px;
    min-height: 130px;
    border-radius: 10px;
    background: #e9effb;
    position: relative;
    border-bottom-width: 0;
    border-style: solid;
    transition: 50ms border-bottom-width ease-in-out;
    box-shadow: 0 0 5px rgba(0,0,0,0.8);
    padding: 20px 10px 20px 52px;
    cursor: pointer;
    user-select: none;
    margin-left: 60px;
    margin-bottom: 30px;
}

.niceButtonAnchor {
    visibility: hidden;
}

.niceButtonIcon {
    position: absolute;
    top: 50px;
    left: 0;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.8);
    transition: 100ms transform ease-in-out;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50px;
}

.niceButton:hover {
    background: #d9e5fa;
    border-bottom-width: 4px;
}

.niceButton:hover .niceButtonIcon {
    transform: translate(-50%, -50%) scale(1.25);
}

.niceButtonTitle {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.niceButtonDescription {
    font-size: 16px;
    font-weight: 300;
}

/* LOGIN */

.loginBlock {
    display: flex;
    flex-direction: column;
    width: 450px;
    padding: 20px;
}

.loginBlockTitle {
    width: 100%;
    font-size: 20px;
    text-align: center;
    user-select: none;
}

.loginBlockParagraph {
    font-size: 14px;
    color: #666;
    user-select: none;
}

.loginInput {
    height: 50px;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 0 10px;
    font-size: 20px;
    text-align: center;
    color: #333;
}

.loginInput::placeholder {
    color: #ccc;
}

.loginButton {
    width: 100px;
    height: 40px;
    font-size: 15px;
    margin: 0 auto;
    background: #A21312;
    color: #fff;
    border-radius: 4px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: 150ms background ease-in-out;
}

.loginButton:hover {
    background: #c42322;
}

.logoutButton {
    cursor: pointer;
    transition: color 150ms ease-in-out;
}

.logoutButton:hover {
    color: #ccc;
}

.warning {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}

.themeButton {
    position: absolute;
    width: 10px;
    height: 100px;
    background: transparent;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
}

/* ADMIN */

.adminWrapper {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    display: flex;
}

.adminSideBar {
    width: 200px;
    height: 100vh;
    background: #272C33;
    padding: 0 20px;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.adminSideBarTitle {
    font-size: 20px;
    text-align: center;
    color: #ccc;
    margin-top: 30px;
    margin-bottom: 20px;
}

.adminSideBarParagraph {
    color: #666;
    text-align: center;
}

.adminSideBarButton {
    min-width: 64px;
    padding-top: 69px;
    color: #fff;
    margin: 20px 0;
    cursor: pointer;
    text-align: center;
}

.adminSideBarButton:hover {
    opacity: 0.6;
}

.adminSideBarButtonReg {
    background: url("../img/register.png") center top no-repeat;
}

.adminSideBarButtonRights {
    background: url("../img/justice.png") center top no-repeat;
}

.adminSideBarButtonEdit {
    background: url("../img/edit.png") center top no-repeat;
}

.adminSideBarButtonKey {
    background: url("../img/key.png") center top no-repeat;
}

.adminSideBarButtonDomain {
    background: url("../img/www.png") center top no-repeat;
}

.adminContent {
    width: calc(100vw - 200px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    /*padding: 30px 60px;*/
    overflow-y: auto;
}

.gammaCheckbox {
    display: flex;
    line-height: 14px;
    user-select: none;
    cursor: pointer;
}

.gammaCheckbox input {
    margin-right: 5px;
}

.rightsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100%;
}

.regFooter {
    width: 100%;
    /*background: red;*/
    justify-content: space-between;
}

.adminTitle {
    font-size: 24px;
    text-align: center;
}

.adminParagraph {
    /*margin-bottom: 10px;*/
}

.adminButton {
    margin-bottom: 15px;
    padding: 15px 10px;
    min-width: 150px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    background: #A21312;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    border: 0;
}

.adminButton:hover {
    background: #c42322;
}

.adminButton:disabled {
    background: #CCCCCC;
    cursor: default;
}

.adminInput {
    background: #fff;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 5px;
    width: 500px;
    padding: 0 10px;
    text-align: center;
    margin-bottom: 20px;
}

.adminInputEnabled {
    border: 1px solid #67B7F7;
}

.adminSearchBlock {
    /*display: none;*/
    opacity: 0;
    padding: 5px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    max-height: 400px;
    background: #fff;
    /*border-top: 2px solid #f6f6f6;*/
    box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
    z-index: 5;
}

.pos-rel:focus-within .adminSearchBlock {
    opacity: 1;
}

.tokenTable tr th {
    font-weight: 600;
}

.tokenTable tr th,
.tokenTable tr td {
    padding: 5px 15px;
}

.tokenCell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}

.copyToken {
    cursor: pointer;
}

.copyToken:hover {
    color: #A21312;
}

.adminSearchUser {
    cursor: pointer;
    user-select: none;
    padding: 4px;
}

.adminSearchUser:hover {
    color: #A21312;
}

.crossIcon {
    color: red;
    margin-left: 10px;
    cursor: pointer;
}

.checkboxContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.projectButtonsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /*margin-bottom: 15px;*/
    overflow-y: auto;
    box-shadow: 0 0 3px 0;
    background: #f6f6f6;
}

.projectButton {
    min-height: 60px;
    width: max(calc(25% - 20px), 250px);
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid transparent;
    user-select: none;
    /*border: 1px solid black;*/
}

.projectButtonTitle {
    height: 60px;
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: 5px 5px;
    padding-left: 70px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /*border-top-left-radius: 10px;*/
    /*border-top-right-radius: 10px;*/
}

.projectButtonTitleInactive {
    filter: grayscale(100%)
}

.projectButtonTitleActive {
    filter: grayscale(0);
    background-color: #67B7F7;
}

.projectButton:hover {
    background-color: #fcfcfc;
    border: 1px solid #d9e5fa;
}

.projectButton:hover .projectButtonTitleInactive{
    filter: grayscale(0);
    background-color: #d9e5fa;
}

.specialRights {
    padding: 5px;
}

.specialRights input {
    margin-right: 5px;
}

.checkboxItem {
    margin: 8px 0;
    cursor: pointer;
}

.checkboxItem:hover {
    color: #666;
}

.checkboxItem input {
    margin-right: 5px;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-end;
    width: 350px;
    max-height: 115px;
    overflow-y: auto;
}

.link {
    margin: 5px 0;
}

/* REG MODAL */

.regModalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    z-index: 50;
}

.regModalContainer {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
}

.regContainer {
    width: 300px;
}

.regTitle {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: default;
}

.regInput {
    width: 100%;
    border: 1px solid #eee;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 5px 0;
    text-align: center;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
}

.regInputPassword {
    text-security: disc;
    -webkit-text-security: disc;
}

.regButton {
    background: #6C7AE0;
    width: 100%;
    color: #fff;
    padding: 15px 25px;
    border-radius: 10px;
    border: 0;
    outline: 0;
    margin-top: 10px;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    text-align: center;
}

.regWarning {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 20px;
    text-align: center;
    color: red;
}

.regMessage {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 20px;
    text-align: center;
    color: darkgreen;
}

.us-none {
    user-select: none;
}

/* TABLE */

.tableWrapper {
    width: 600px;
    overflow-y: auto;
    background: #6C7AE0;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    border-radius: 20px;
    padding-right: -5px;
    max-height: calc(100vh - 130px);

    scrollbar-width: none;
}

.tableWrapper::-webkit-scrollbar {
    width: 0;
}

.tableWrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #eee;
}

.tableWrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #CCCCCC;
}

.table {
    width: 100%;
    border-radius: 20px;
}

.table tr {
    background: #fff;
}

.table tr:nth-child(2n) {
    background: #F8F6FF;
}

/*.table tr:hover {*/
/*    background: #f0eeff;*/
/*}*/

.table tr td {
    padding: 10px 20px;
    vertical-align: middle;
}

.table tr td {
    color: #000;
}

.phoneCell {
    text-align: right;
}

.cellHover {
    color: #666 !important;
    cursor: pointer;
}

.cellHover:hover {
    background: #f0eeff;
}

/* DARK */
.dark .globalWrapper {
    background-color: #1D1D1D;
    color: #B2B6B9;
}

.dark .mainContainer {
    background: #232528;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.46);
}

.dark .userBar {
    background: transparent;
    border-bottom: 1px solid #333;
    color: #B2B6B9;
}

.dark .niceButton {
    /*background: #365F7D;*/
    background: #29324d;
    color: #fff;
}

.dark .niceButton:hover {
    background: #303a58;
    /*background: #3E7594;*/
}

/*.dark .niceButtonIcon {*/
/*    box-shadow: 0 0 5px rgba(0,0,0,0.8);*/
/*}*/

.dark .loginBlockParagraph {
    color: #B2B6B9;
}

.dark .loginInput {
    border: 1px solid #B2B6B9;
    background: transparent;
    color: #B2B6B9;
}

.dark .loginInput::placeholder {
    color: #3e3e40;
}

.dark .loginButton {
    background: #42a5f5;
    color: #fff;
}

.dark .loginButton:hover {
    background: #67B7F7;
}

/*scrollbar*/
.buttonsContainer {
    scrollbar-color: #ccc transparent; /* FF */
}
.dark .buttonsContainer {
    scrollbar-color: #29324d transparent; /* FF */
}
.buttonsContainer::-webkit-scrollbar {
    width: 15px;
}
.buttonsContainer::-webkit-scrollbar-track {
    background: transparent;
}
.buttonsContainer::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}
.buttonsContainer::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
    border: 1px solid #ccc;
}
.dark .buttonsContainer::-webkit-scrollbar-thumb {
    background-color: #29324d;
    border: 1px solid #3c3c3c;
}
.dark .buttonsContainer::-webkit-scrollbar-thumb:hover {
    background-color: #303a58;
}


/* BASICS */

.h-100p {
    height: 100%;
}

.d-flex {
    display: flex;
}

.fd-column {
    flex-direction: column;
}

.jc-center {
    justify-content: center;
}

.pos-rel {
    position: relative;
}

.ai-center {
    align-items: center;
}

.ml-20 {
    margin-left: 20px;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-10 {
    margin-left: 10px;
}

.w-100p {
    width: 100%;
}

.w-240px {
    width: 240px;
}

.mh-400 {
    max-height: 400px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.cursor-default {
    cursor: default;
}

.color-red {
    color: red;
}

@media screen and (max-width: 1200px) {
    .buttonsContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .buttonsContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .mainWrapper {
        padding: 0;
    }
    .mainContainer {
        border-radius: 0;
        width: 100%;
        box-shadow: none;
    }
    .userBar {
        flex-direction: column;
        font-size: 16px;
    }
    .logoutButton {
        margin-top: 20px;
        color: #ccc;
    }
    .niceButton {
        width: 240px;
        min-height: 100px;
        height: fit-content;
    }
    .loginBlock {
        max-width: 100%;
    }
}