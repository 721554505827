.wrapper404 {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    user-select: none;
    cursor: default;
}

.container404 {
    height: 100vh;
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.code404 {
    font-size: 40px;
    margin-bottom: 20px;
    z-index: 3;
}

.message404 {
    z-index: 3;
}

.video404 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
}